define("moments/components/campaign-logo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-piece-wrapper', 'campaign-logo-wrapper'],
    logoUrl: null
  });

  _exports.default = _default;
});