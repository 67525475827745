define("moments/components/comment-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UGSWDrR1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"messages \",[28,\"if\",[[24,[\"showComments\"]],\"active\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"numComments\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"num-comments\"],[8],[1,[22,\"numComments\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"button\",false],[12,\"class\",\"basic-button\"],[3,\"action\",[[23,0,[]],\"clickToggle\"]],[8],[0,\"\\n    \"],[7,\"i\",true],[10,\"class\",\"material-icons messages-icon\"],[8],[1,[22,\"icon\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/comment-icon/template.hbs"
    }
  });

  _exports.default = _default;
});