define("moments/components/overlay-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t4oWPTpL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"overlay-background\"],[11,\"style\",[29,[\"background: \",[24,[\"options\",\"color\"]]]]],[8],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"overlay-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"progress-bar-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"progress-bar\"],[11,\"style\",[29,[\"background: \",[24,[\"options\",\"color\"]]]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"partial-progress\"],[11,\"style\",[29,[\"width: \",[24,[\"options\",\"width\"]],\"%; transition: width \",[24,[\"options\",\"duration\"]],\"s linear;\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"notificationIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"i\",true],[11,\"class\",[29,[\"material-icons \",[22,\"notificationIcon\"]]]],[8],[1,[22,\"notificationIcon\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text style-body\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[24,[\"options\",\"text\"]],true],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/overlay-screen/template.hbs"
    }
  });

  _exports.default = _default;
});