define("moments/components/layouts/two-content-blocks-layout/component", ["exports", "moments/components/layouts/base-layout/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layoutName: 'components/layouts/base-layout',
    layoutPartNames: ['content-block-secondary', 'content-block-primary'],
    layoutClass: 'two-content-blocks'
  });

  _exports.default = _default;
});