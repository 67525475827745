define("moments/services/ajax", ["exports", "moments/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    request: function request(type, endpoint, data) {
      var baseUrl = _environment.default.APP.apiRequestUrl;
      var url = "".concat(baseUrl, "/").concat(endpoint);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          type: type,
          url: url,
          data: data
        }).then(resolve, reject);
      });
    }
  });

  _exports.default = _default;
});