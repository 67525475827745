define("moments/services/google-analytics", ["exports", "striptags"], function (_exports, _striptags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setUserId: function setUserId(sessionId) {
      if (!window.gtag) {
        return;
      }

      return window.gtag('set', {
        userId: sessionId
      });
    },
    sendPageView: function sendPageView(contentCollection) {
      if (!window.gtag || !contentCollection) {
        return;
      }

      var _window$location = window.location,
          protocol = _window$location.protocol,
          pathname = _window$location.pathname,
          search = _window$location.search,
          origin = _window$location.origin;
      var contentCollectionId = contentCollection.get('id');
      var path = "".concat(pathname, "/screen/").concat(contentCollectionId);
      var url = "".concat(protocol, "//").concat(origin).concat(path).concat(search);
      var text = contentCollection.get('contentPieces').sortBy('displayOrder').filterBy('type', 'text').mapBy('value').objectAt(0);
      return window.gtag('event', 'pageview', {
        location: url,
        page: path,
        title: (0, _striptags.default)(text),
        questionId: contentCollectionId,
        name: 'Jebbit'
      });
    },
    sendVideoEvent: function sendVideoEvent(eventAction, videoLayoutPartNme) {
      if (!window.gtag) {
        return;
      }

      return window.gtag('event', {
        eventCategory: 'Videos',
        eventAction: eventAction,
        eventLabel: videoLayoutPartNme
      });
    }
  });

  _exports.default = _default;
});