define("moments/utils/sanitize-query-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = sanitizeQueryString;

  function sanitizeQueryString() {
    var url = window.location.search;
    url = url.replace(/\?/g, '&');
    url = url.replace('&', '?');
    window.history.replaceState({}, document.title, url);
  }
});