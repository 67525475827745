define("moments/components/content-piece/social-share/component", ["exports", "moments/components/content-piece/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    contentPiece: null,
    onShare: function onShare() {},
    contentCollection: Ember.computed.oneWay('contentPiece.contentCollection'),
    facebookButtonText: Ember.computed.oneWay('value.facebookButtonText'),
    twitterButtonText: Ember.computed.oneWay('value.twitterButtonText'),
    value: Ember.computed.oneWay('contentPiece.value'),
    socialSharingMetadata: Ember.computed('contentCollection.{socialSharingMetadata,outcome}', function () {
      var contentCollection = this.get('contentCollection');
      var outcomeSharingData = contentCollection.get('outcome.socialSharingMetadata');
      var normalSharingData = contentCollection.get('socialSharingMetadata');

      if (contentCollection.get('type') === 'Outcome' && outcomeSharingData && outcomeSharingData.get('content')) {
        return outcomeSharingData;
      } else {
        return normalSharingData;
      }
    }),
    actions: {
      didShare: function didShare() {
        this.get('onShare').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});