define("moments/transforms/array", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(deserialized) {
      if (Ember.typeOf(deserialized) === 'array') {
        return deserialized;
      } else {
        return [];
      }
    },
    deserialize: function deserialize(serialized) {
      var deserialized;

      switch (Ember.typeOf(serialized)) {
        case 'array':
          deserialized = serialized;
          break;

        case 'string':
          deserialized = serialized.split(',').map(function (item) {
            _jquery.default.trim(item);
          });
          break;

        default:
          deserialized = [];
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});