define("moments/services/overlay-screen-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isShowing: Ember.computed.equal('showScreen'),
    scheduledHide: null,
    showScreen: false,
    options: null,
    hide: function hide() {
      this.set('showScreen', false);
      this.set('options.width', 100);
      this.set('options.duration', 0);
      Ember.run.cancel(this.get('scheduledHide'));
    },
    show: function show(options) {
      this.set('options', options);
      this.set('showScreen', true);
      this.set('options.width', 0);
      this.scheduleHide(options.duration);
    },
    scheduleHide: function scheduleHide(duration) {
      var _this = this;

      var scheduledHide = Ember.run.later(function () {
        _this.hide();
      }, duration * 1000);
      this.set('scheduledHide', scheduledHide);
    }
  });

  _exports.default = _default;
});