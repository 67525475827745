define("moments/components/social-button/base/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['basic-button social-button'],
    tagName: 'button',
    onShare: function onShare() {},
    socialData: null
  });

  _exports.default = _default;
});