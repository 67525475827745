define("moments/instance-initializers/irregular-inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* appInstance */
  {
    var inflector = _emberInflector.default.inflector;
    inflector.irregular('metadata', 'metadata');
  }

  var _default = {
    name: 'irregular-inflector',
    initialize: initialize
  };
  _exports.default = _default;
});