define("moments/utils/randomize-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = randomizeArray;

  function randomizeArray(list) {
    if (!list || list.length <= 1) {
      return list;
    }

    return shuffle(list);
  }
  /**
   * shuffles an array based on the Fisher-Yates shuffle
   * https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
   * @param {array} array
   */


  function shuffle(array) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex; // While there remain elements to shuffle...

    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1; // And swap it with the current element.

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
});