define("moments/services/redirect-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setFinalUrl: function setFinalUrl(redirect, session) {
      var protocolRegex = /http[s]{0,1}:\/\//;

      var _redirect$getProperti = redirect.getProperties(['urlIsFinalized', 'forwardUrlParams', 'url']),
          urlIsFinalized = _redirect$getProperti.urlIsFinalized,
          forwardUrlParams = _redirect$getProperti.forwardUrlParams,
          url = _redirect$getProperti.url;

      if (forwardUrlParams && !urlIsFinalized) {
        url = this.appendUrlParams(url, session);
      }

      if (!protocolRegex.test(url)) url = "http://".concat(url);
      redirect.setProperties({
        url: url,
        urlIsFinalized: true
      });
    },
    appendUrlParams: function appendUrlParams(url, session) {
      var appendingChar = url.indexOf('?') === -1 ? '?' : '&';
      var urlParams = session.get('urlParams');

      if (Ember.isEmpty(urlParams)) {
        return url;
      }

      if (urlParams.indexOf('?') === 0) {
        urlParams = urlParams.substr(1, urlParams.length - 1);
      }

      return "".concat(url).concat(appendingChar).concat(urlParams);
    }
  });

  _exports.default = _default;
});