define("moments/utils/handle-redirect", ["exports", "moments/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handleRedirect;

  function handleRedirect(session, newTabRedirect, delay) {
    var redirect = getRedirectOptions(session, newTabRedirect);
    var redirectUrl = redirect.get('url');

    if (!redirectUrl) {
      return {
        status: 'failed',
        redirectUrl: null
      };
    }

    if (redirect.get('forwardUrlParams')) {
      redirectUrl = appendUrlParams(redirectUrl, session);
    }

    if (_environment.default.environment === 'test') {
      Ember.debug("Redirect detected: ".concat(redirectUrl));
      return {
        status: 'success',
        redirectUrl: redirectUrl
      };
    }

    if (redirect.get('openInNewTab')) {
      var newWindow = window.open(redirectUrl, '_blank');

      if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
        return {
          status: 'blocked',
          redirectUrl: redirectUrl
        };
      }
    } else {
      if (!delay) {
        delay = 0;
      }

      Ember.run.later(function () {
        window.location.href = redirectUrl;
      }, delay);
    }

    return {
      status: 'success',
      redirectUrl: redirectUrl
    };
  }

  function getRedirectOptions(session, newTabRedirect) {
    if (newTabRedirect) {
      return Ember.Object.create({
        openInNewTab: true,
        url: Ember.get(newTabRedirect, 'url'),
        forwardUrlParams: Ember.get(newTabRedirect, 'forwardUrlParams')
      });
    } else if (session.get('redirect')) {
      return session.get('redirect');
    } else {
      return Ember.Object.create();
    }
  }

  function appendUrlParams(redirectUrl, session) {
    var appendingChar = redirectUrl.indexOf('?') === -1 ? '?' : '&';
    var urlParams = session.get('urlParams');

    if (Ember.isEmpty(urlParams)) {
      return redirectUrl;
    }

    if (urlParams.indexOf('?') === 0) {
      urlParams = urlParams.substr(1, urlParams.length - 1);
    }

    return "".concat(redirectUrl).concat(appendingChar).concat(urlParams);
  }
});