define("moments/components/content-piece/form-field/component", ["exports", "moments/utils/strip-html-tags"], function (_exports, _stripHtmlTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var dasherize = Ember.String.dasherize;

  var _default = Ember.Component.extend({
    classNames: ['form-field-wrapper'],
    classNameBindings: ['wrapperClass'],
    randomizeContent: false,
    didSubmitForm: false,
    formField: null,
    onClick: function onClick() {},
    onSocialLoginClick: function onSocialLoginClick() {},
    hasFocused: false,
    type: Ember.computed.oneWay('formField.type'),
    isCheckbox: Ember.computed.oneWay('formField.isCheckbox'),
    actions: {
      click: function click() {
        this.get('onClick')();
      },
      socialLoginClick: function socialLoginClick() {
        this.get('onSocialLoginClick')(this.get('formField'));
      }
    },
    buttonClass: Ember.computed('formField.id', 'styleClass', function () {
      return "basic-button button-".concat(this.get('formField.id'), " ").concat(this.get('styleClass'));
    }),
    checkboxClass: Ember.computed('formField.isChecked', 'formFieldClass', 'styleClass', function () {
      var isChecked = this.get('formField.isChecked') ? 'is-checked' : '';
      return "".concat(this.get('formFieldClass'), " ").concat(this.get('styleClass'), " ").concat(isChecked, " checkbox");
    }),
    formFieldClass: Ember.computed('formField.id', function () {
      return "form-field form-field-".concat(this.get('formField.id'), " content");
    }),
    formStyleClass: Ember.computed('formField.formStyle', function () {
      return this.get('formField.formStyle') === 'Material' ? 'paper-input' : 'plain-input';
    }),
    fieldErrors: Ember.computed('formField.validations.attrs.{value.messages,isChecked.messages}', 'hasFocused', function () {
      if (!this.get('hasFocused')) {
        return [];
      }

      var valueMessages = this.get('formField.validations.attrs.value.messages') || [];
      var isCheckedMessages = this.get('formField.validations.attrs.isChecked.messages') || [];
      return valueMessages.concat(isCheckedMessages).map(_stripHtmlTags.default);
    }),
    inputClass: Ember.computed('formFieldClass', 'styleClass', 'formStyleClass', function () {
      return "".concat(this.get('formFieldClass'), " ").concat(this.get('styleClass'), " text-input ").concat(this.get('formStyleClass'));
    }),
    inputId: Ember.computed('formField.id', function () {
      return "input-".concat(this.get('formField.id'));
    }),
    sliderClass: Ember.computed('formFieldClass', 'styleClass', function () {
      return "".concat(this.get('formFieldClass'), " ").concat(this.get('styleClass'));
    }),
    styleClass: Ember.computed('formField.style', function () {
      var style = this.get('formField.style');

      if (style) {
        return "style-".concat(dasherize(style));
      }
    }),
    textClass: Ember.computed('formFieldClass', 'styleClass', function () {
      return "".concat(this.get('formFieldClass'), " ").concat(this.get('styleClass'));
    }),
    wrapperClass: Ember.computed('formField.{style,id,displayOrder}', function () {
      var id = this.get('formField.id');
      var displayOrder = this.get('formField.displayOrder');
      var wrapperPrefix = 'form-field-wrapper';
      var style = this.get('formField.style');
      var styleClass;

      if (style) {
        styleClass = "".concat(dasherize(style), "-wrapper");
      }

      return "".concat(wrapperPrefix, "-").concat(id, " ").concat(wrapperPrefix, "-").concat(displayOrder, " ").concat(styleClass);
    }),
    focusIn: function focusIn() {
      var _this = this;

      Ember.run.next(function () {
        _this.set('hasFocused', true);
      });
    }
  });

  _exports.default = _default;
});