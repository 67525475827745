define("moments/services/query-params-service", ["exports", "moments/utils/sanitize-query-string"], function (_exports, _sanitizeQueryString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function normalizeKey(key) {
    return Ember.String.camelize(key).toLowerCase();
  }

  var _default = Ember.Service.extend({
    params: Ember.computed(function () {
      (0, _sanitizeQueryString.default)();
      var queryStr = window.location.search.replace('?', '');
      return queryStr.split('&').reduce(function (queries, query) {
        var _query$split = query.split('='),
            _query$split2 = _slicedToArray(_query$split, 2),
            key = _query$split2[0],
            value = _query$split2[1];

        if (value === 'true' || value === 'false') {
          value = value === 'true';
        }

        if (key && value) {
          if (key.indexOf('[]') > -1) {
            key = key.replace('[]', '');
            queries[key] = queries[key] || [];
            queries[key].push(value);
          } else {
            queries[key] = value;
          }
        }

        return queries;
      }, {});
    }),
    updateParam: function updateParam(paramName, value) {
      var url = window.location.href;

      if (this.findParam(paramName)) {
        var regex = new RegExp("".concat(paramName, "=([^&]*)&?"));
        url = url.replace(regex, "".concat(paramName, "=").concat(value));
      } else {
        var append = url.indexOf('?') > -1 ? '&' : '?';
        url += "".concat(append).concat(paramName, "=").concat(value);
      }

      this.set("params.".concat(paramName), value);
      this.set("_normalizedParams.".concat(normalizeKey(paramName)), value);
      window.history.replaceState({}, document.title, url);
    },
    findParam: function findParam(paramName) {
      return this.get("_normalizedParams.".concat(normalizeKey(paramName)));
    },
    removeParam: function removeParam(paramName) {
      var history = window.history;
      var param = this.findParam(paramName);

      if (!history || !history.replaceState || !param) {
        return;
      }

      var regex = new RegExp("".concat(paramName, "=([^&]*)&?"));
      var url = window.location.href.replace(regex, '');
      history.replaceState({}, document.title, url);
      return param;
    },
    _normalizedParams: Ember.computed('params', function () {
      var params = this.get('params');
      var normalizedParams = {};
      Object.keys(params).forEach(function (key) {
        var value = params[key];
        normalizedParams[normalizeKey(key)] = value;
      });
      return normalizedParams;
    })
  });

  _exports.default = _default;
});