define("moments/components/content-piece/form-field/checkbox-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MMkFRiAn",
    "block": "{\"symbols\":[\"checkbox\",\"i\"],\"statements\":[[4,\"each\",[[24,[\"checkboxes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"layout-row flex checkbox-row\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"class\",\"checked\",\"click\"],[[23,1,[\"inputId\"]],\"checkbox\",\"form-field-checkbox\",[23,1,[\"isChecked\"]],[28,\"action\",[[23,0,[]],\"clickCheckbox\",[23,1,[]]],null]]]],false],[0,\"\\n\\n    \"],[7,\"label\",false],[12,\"class\",[29,[\"form-field style-body \",[23,1,[\"class\"]]]]],[12,\"for\",[29,[[23,1,[\"inputId\"]]]]],[3,\"action\",[[23,0,[]],\"clickCheckbox\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[\"text\"]],true],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"fieldErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[22,\"checkboxClass\"]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"paper-input-error\"],[8],[0,\"\\n      \"],[1,[22,\"fieldErrors\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form-field/checkbox-array/template.hbs"
    }
  });

  _exports.default = _default;
});