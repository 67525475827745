define("moments/utils/strip-html-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = stripHtmlTags;

  function stripHtmlTags(string) {
    var element = window.document.createElement('div');
    element.innerHTML = string;
    return element.textContent;
  }
});