define("moments/models/content-collection", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    campaign: belongsTo('campaign'),
    contentPieces: hasMany('content-piece'),
    isDataBranch: attr('boolean'),
    layoutId: attr('string'),
    name: attr('string'),
    previewOutcome: belongsTo('outcome'),
    response: belongsTo('response'),
    nodeTrackingPixels: hasMany('node-tracking-pixel'),
    socialSharingMetadata: belongsTo('social-sharing-metadata'),
    randomizeContent: attr('boolean'),
    type: attr('string'),
    url: attr('string'),
    outcome: null,
    feedOutcomePresent: false,
    userHasResponded: false,
    layoutComponent: Ember.computed('layoutId', function () {
      return "layouts/".concat(this.get('layoutId'), "-layout");
    }),
    sortedContentPieces: Ember.computed('contentPieces.[]', function () {
      return this.get('contentPieces').sortBy('displayOrder');
    })
  });

  _exports.default = _default;
});