define("moments/helpers/truncate-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateText = truncateText;
  _exports.default = void 0;

  function truncateText(params) {
    var string = params[0] || '';
    var limit = params[1] || 50;
    return string.length > limit ? "".concat(string.substr(0, limit).trim(), "...") : string;
  }

  var _default = Ember.Helper.helper(truncateText);

  _exports.default = _default;
});