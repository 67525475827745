define("moments/utils/render-handlebars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = handlebarsRender;

  // this is sample {{text}}, { text: 'hello world', image_link: 'http://google.com', ... } => this is sample hello world
  // this is sample {{text}}, {}, => this is sample
  function handlebarsRender(value, contextHash) {
    var regex = /{{(.*?)}}/g;
    var matches = value.match(regex);

    if (!matches) {
      return value;
    }

    var replacedValue = value;
    matches.forEach(function (match) {
      var matchWithoutBrackets = match.replace(/{/g, '').replace(/}/g, '');
      var contextValue = contextHash[matchWithoutBrackets];

      if (contextValue) {
        replacedValue = replacedValue.replace(match, contextValue);
      }
    });
    return replacedValue.replace(regex, '');
  }
});