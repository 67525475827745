define("moments/components/campaign-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4kLnTSyk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"class\",\"logo\"],[11,\"src\",[29,[[22,\"logoUrl\"]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/campaign-logo/template.hbs"
    }
  });

  _exports.default = _default;
});