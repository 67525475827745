define("moments/utils/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getMetaData = getMetaData;

  function getMetaData(error, container) {
    var location = window.location;
    var session_id = container.lookup('service:state-manager').get('session.id');
    var campaign_link = "".concat(location.origin).concat(location.pathname);
    return {
      user: {
        campaign_link: campaign_link,
        session_id: session_id
      }
    };
  }
});