define("moments/instance-initializers/validators", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* appInstance */
  {
    _base.default.reopen({
      createErrorMessage: function createErrorMessage(type, value) {
        var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        options.message = this.get('model.validationMessage') || options.message;
        return this._super(type, value, options);
      }
    });
  }

  var _default = {
    name: 'validators',
    initialize: initialize
  };
  _exports.default = _default;
});