define("moments/components/social-button/facebook/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "umdl27uB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"class\",\"social-icon\"],[10,\"src\",\"/images/facebook-icon-white.png\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[1,[22,\"text\"],true],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/social-button/facebook/template.hbs"
    }
  });

  _exports.default = _default;
});