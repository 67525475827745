define("moments/services/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stateManager: Ember.inject.service(),
    identifyUser: function identifyUser() {
      var _window = window,
          bugsnagClient = _window.bugsnagClient;
      var session = Ember.get(this, 'stateManager.session');
      if (!bugsnagClient) return;
      bugsnagClient.user = {
        id: Ember.get(session, 'id'),
        authToken: Ember.get(session, 'authToken')
      };
    }
  });

  _exports.default = _default;
});