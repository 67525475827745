define("moments/models/data-action", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    dataBranch: belongsTo('data-branch'),
    edge: belongsTo('edge'),
    value: attr('string'),
    distributionLinkData: attr('object')
  });

  _exports.default = _default;
});