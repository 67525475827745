define("moments/models/response", ["exports", "ember-data", "moments/mixins/has-session-mixin"], function (_exports, _emberData, _hasSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend(_hasSessionMixin.default, {
    edge: belongsTo('edge'),
    endedAt: attr('string'),
    formFieldSubmissions: hasMany('form-field-submission'),
    startedAt: attr('string')
  });

  _exports.default = _default;
});