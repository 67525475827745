define("moments/components/notification-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QiJKC2gl",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"notificationIcon\"]]],null,{\"statements\":[[0,\" \"],[7,\"div\",true],[11,\"class\",[29,[[24,[\"styles\",\"c-notification__icon\"]]]]],[8],[0,\"\\n  \"],[7,\"i\",true],[11,\"class\",[29,[\"material-icons md-24 \",[22,\"notificationIcon\"]]]],[8],[1,[22,\"notificationIcon\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[11,\"class\",[29,[[24,[\"styles\",\"c-notification__content\"]],\" notification-message\"]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"notification\",\"htmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,[\"notification\",\"message\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"notification\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[24,[\"styles\",\"c-notification__countdown\"]]]]],[11,\"style\",[22,\"notificationClearDuration\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/notification-message/template.hbs"
    }
  });

  _exports.default = _default;
});