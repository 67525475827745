define("moments/models/form-field-submission", ["exports", "ember-data", "moments/mixins/has-session-mixin"], function (_exports, _emberData, _hasSessionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend(_hasSessionMixin.default, {
    formField: belongsTo('form-field'),
    value: attr('string')
  });

  _exports.default = _default;
});