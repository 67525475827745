define("moments/components/comment-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    icon: 'message',
    numComments: null,
    onClick: function onClick() {},
    actions: {
      clickToggle: function clickToggle() {
        this.onClick();
      }
    }
  });

  _exports.default = _default;
});