define("moments/components/content-piece/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HG/RkeU5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"text \",[22,\"styleClass\"]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"content \",[22,\"contentClass\"]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"styleClass\"]],\"style-heading\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"h1\",true],[8],[1,[24,[\"contentPiece\",\"value\"]],true],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,[\"contentPiece\",\"value\"]],true],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/text/template.hbs"
    }
  });

  _exports.default = _default;
});