define("moments/models/data-branch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    dataActions: hasMany('data-action'),
    type: attr('string'),
    value: attr('string')
  });

  _exports.default = _default;
});