define("moments/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6iQozEM/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"not-found\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"Sorry that page does not exist!\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});