define("moments/components/content-piece/social-share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sSp2fFa4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"social-buttons-wrapper\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"social-sharing-buttons content-\",[24,[\"contentPiece\",\"id\"]]]]],[8],[0,\"\\n    \"],[1,[28,\"social-button/facebook\",null,[[\"text\",\"onShare\",\"socialData\"],[[24,[\"facebookButtonText\"]],[28,\"action\",[[23,0,[]],\"didShare\"],null],[24,[\"socialSharingMetadata\"]]]]],false],[0,\"\\n\\n    \"],[1,[28,\"social-button/twitter\",null,[[\"text\",\"onShare\",\"socialData\"],[[24,[\"twitterButtonText\"]],[28,\"action\",[[23,0,[]],\"didShare\"],null],[24,[\"socialSharingMetadata\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/social-share/template.hbs"
    }
  });

  _exports.default = _default;
});