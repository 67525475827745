define("moments/models/pixels-edge", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    edge: belongsTo('edge'),
    pixelsNode: belongsTo('pixels-node'),
    eventValue: attr('string'),
    pixel: belongsTo('pixel')
  });

  _exports.default = _default;
});