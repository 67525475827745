define("moments/instance-initializers/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    Ember.onerror = function (error) {
      var bugsnagService = appInstance.lookup('service:bugsnag');
      var _window = window,
          bugsnagClient = _window.bugsnagClient;

      if (bugsnagClient) {
        bugsnagService.identifyUser();
        bugsnagClient.notify(error);
      }
    };
  }

  var _default = {
    name: 'bugsnag',
    initialize: initialize
  };
  _exports.default = _default;
});