define("moments/components/moments-navigation-toolbar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showNavMenu: false,
    navIcon: 'keyboard_arrow_up',
    onStartOver: function onStartOver() {},
    onChooseScreen: function onChooseScreen() {},
    onChooseOutcome: function onChooseOutcome() {},
    actions: {
      startOver: function startOver() {
        this.get('onStartOver')();
      },
      chooseScreen: function chooseScreen(contentCollection) {
        this.get('onChooseScreen')(contentCollection);
      },
      chooseOutcome: function chooseOutcome(outcome) {
        this.get('onChooseOutcome')(outcome);
      },
      toggleCampaignNavPanel: function toggleCampaignNavPanel() {
        var menuState = this.toggleProperty('showNavMenu');
        var icon = menuState ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
        this.set('navIcon', icon);
      }
    }
  });

  _exports.default = _default;
});