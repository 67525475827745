define("moments/services/neustar-tag-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sendFormSubmission: function sendFormSubmission(contentCollection, formFields) {
      var pixels = this.store.peekAll('pixel');
      var neustarPixel = pixels.find(function (pixel) {
        return pixel.get('serviceName') === 'neustar';
      });
      this.sendPageView(contentCollection, 'on_response');

      if (!neustarPixel || !contentCollection) {
        return;
      }

      var formIds = formFields.map(function (form) {
        return form.id;
      });
      var nodeName = this.formatName(contentCollection.get('name'));
      var pixelsFormFields = this.store.peekAll('pixelsFormField');
      var pixelsFormFieldsToFire = pixelsFormFields.filter(function (pixelsFormField) {
        var pixelsFormFieldFormId = pixelsFormField.get('formField.id');
        return formIds.includes(pixelsFormFieldFormId);
      });
      pixelsFormFieldsToFire.forEach(function (pixelFormField) {
        var pixelsFormFieldEventValue = pixelFormField.get('eventValue');
        window["_agknEchoTag"].addEchoKeyValue(nodeName, pixelsFormFieldEventValue);
      });
      this.fireTag(pixelsFormFieldsToFire);
    },
    sendResponses: function sendResponses(contentCollection, contentPiece) {
      var pixels = this.store.peekAll('pixel');
      var neustarPixel = pixels.find(function (pixel) {
        return pixel.get('serviceName') === 'neustar';
      });
      this.sendPageView(contentCollection, 'on_response');

      if (!neustarPixel || !contentCollection || !contentPiece) {
        return;
      }

      var edgeId = contentPiece.get('edge.id');
      var nodeName = this.formatName(contentCollection.get('name'));
      var pixelsEdges = this.store.peekAll('pixelsEdge');
      var pixelsEdgesToFire = pixelsEdges.filter(function (pixelsEdge) {
        return pixelsEdge.get('edge.id') === edgeId;
      });
      pixelsEdgesToFire.forEach(function (pixelEdge) {
        var pixelsEdgeEventValue = pixelEdge.get('eventValue');
        window["_agknEchoTag"].addEchoKeyValue(nodeName, pixelsEdgeEventValue);
      });
      this.fireTag(pixelsEdgesToFire);
    },
    sendPageView: function sendPageView(contentCollection) {
      var eventType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'on_load';
      var pixels = this.store.peekAll('pixel');
      var neustarPixel = pixels.find(function (pixel) {
        return pixel.get('serviceName') === 'neustar';
      });

      if (!neustarPixel || !contentCollection) {
        return;
      }

      var nodeId = contentCollection.get('id');
      var nodeName = this.formatName(contentCollection.get('name'));
      var pixelsNodes = this.store.peekAll('pixelsNode');
      var pixelsNodesToFire = pixelsNodes.filter(function (pixelsNode) {
        var pixelNodeMatch = pixelsNode.get('node.id') === nodeId;
        var eventTypeMatch = pixelsNode.get('eventType') === eventType;
        var eventValueNotNull = !Ember.isEmpty(pixelsNode.get('eventValue'));
        return pixelNodeMatch && eventTypeMatch && eventValueNotNull;
      });
      pixelsNodesToFire.forEach(function (pixelNode) {
        var eventValue = pixelNode.get('eventValue');
        window["_agknEchoTag"].addEchoKeyValue(nodeName, eventValue);
      });
      this.fireTag(pixelsNodesToFire);
    },

    /*------------ Helper Functions ------------*/
    fireTag: function fireTag(pixelsObjectsToFire) {
      if (!Ember.isEmpty(pixelsObjectsToFire)) {
        window["_agknEchoTag"].generateTag();
      }
    },
    formatName: function formatName(name) {
      return name.split(' ').join('_').toLowerCase();
    }
  });

  _exports.default = _default;
});