define("moments/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUir5O7m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress-bar\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"partial-progress\"],[11,\"style\",[29,[\"width: \",[22,\"widthPercentage\"],\"%;\"]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});