define("moments/models/form-field", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var Validations = (0, _emberCpValidations.buildValidations)({
    isChecked: [(0, _emberCpValidations.validator)('inclusion', {
      disabled: Ember.computed.not('enabled'),
      enabled: Ember.computed('model.{isRequired,type}', function () {
        var model = this.get('model');
        return model.get('isRequired') && model.get('isCheckbox');
      }),
      in: [true],
      message: 'This item must be checked',
      description: Ember.computed.readOnly('model.text')
    })],
    value: [(0, _emberCpValidations.validator)('presence', {
      disabled: Ember.computed.not('enabled'),
      enabled: Ember.computed('model.{isRequired,type}', function () {
        var model = this.get('model');
        return model.get('isRequired') && !model.get('isCheckbox');
      }),
      presence: true,
      message: Ember.computed('model.type', function () {
        var type = this.get('model.type');

        if (type === 'checkbox-array') {
          return 'Please check at least one option';
        } else if (type === 'slider') {
          return 'Please select a value';
        } else {
          return '{description} cannot be blank';
        }
      }),
      description: Ember.computed.readOnly('model.text')
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      allowBlank: true,
      disabled: Ember.computed.not('enabled'),
      enabled: Ember.computed('model.{text,type,inputType}', function () {
        var model = this.get('model');
        var regex = /email/i;
        return model.get('type') === 'input' && (regex.test(model.get('text')) || model.get('inputType') === 'Email');
      })
    }), (0, _emberCpValidations.validator)('format', {
      type: 'phone',
      allowBlank: true,
      disabled: Ember.computed.not('enabled'),
      enabled: Ember.computed('model.{text,type,inputType}', function () {
        var model = this.get('model');
        var text = model.get('text');
        var hasTextLabel = text.match(/phone number/gi) || text.match(/phone #/gi) || text.toLowerCase() === 'phone' || text.toLowerCase() === 'phone*';
        return model.get('type') === 'input' && (hasTextLabel || model.get('inputType') === 'Phone Number');
      })
    })]
  });

  var _default = Model.extend(Validations, {
    contentPiece: belongsTo('content-piece'),
    additionalProperties: attr('object'),
    displayOrder: attr('number'),
    formStyle: attr('string'),
    isRequired: attr('boolean'),
    style: attr('string'),
    text: attr('string'),
    type: attr('string'),
    isChecked: attr('boolean'),
    inputType: attr('string'),
    dropdownOptions: attr('array'),
    validationMessage: attr('string'),
    value: null,
    label: Ember.computed('isRequired', 'text', function () {
      var _this$getProperties = this.getProperties('isRequired', 'text'),
          isRequired = _this$getProperties.isRequired,
          text = _this$getProperties.text;

      return isRequired ? "".concat(text, "*") : text;
    }),
    isCheckbox: Ember.computed('type', function () {
      return ['checkbox', 'opt-in'].indexOf(this.get('type')) > -1;
    })
  });

  _exports.default = _default;
});