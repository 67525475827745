define("moments/models/node", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    edges: hasMany('edge', {
      inverse: 'parentNode'
    }),
    resourceType: attr('string'),
    response: belongsTo('response'),
    isContentNode: Ember.computed('resourceType', function () {
      var resourceType = this.get('resourceType');
      return ['ContentCollection', 'Outcome'].indexOf(resourceType) > -1;
    })
  });

  _exports.default = _default;
});