define("moments/components/social-button/facebook/component", ["exports", "moments/components/social-button/base/component", "moments/config/environment"], function (_exports, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['facebook'],
    click: function click() {
      var _this = this;

      var socialData = this.get('socialData') || {};
      var url = Ember.get(socialData, 'facebookUrl');

      if (!window.FB) {
        return;
      }

      window.FB.ui({
        method: 'share',
        href: url
      }, function (response) {
        if (response && !response.error_message) {
          _this.get('onShare')('Facebook', response);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: _environment.default.APP.facebook.appId,
          xfbml: true,
          version: 'v3.0'
        });
      };

      (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
          return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  });

  _exports.default = _default;
});