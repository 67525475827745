define("moments/components/social-button/twitter/component", ["exports", "moments/components/social-button/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['twitter'],
    click: function click() {
      window.open(this.get('tweetUrl'), '_blank');
      this.get('onShare')('Twitter');
    },
    tweetUrl: Ember.computed('socialData', function () {
      var baseUrl = 'https://twitter.com/intent/tweet';
      var socialData = this.get('socialData') || {};

      var _EmberGetProperties = Ember.getProperties(socialData, ['suggestedTweet', 'twitterUrl']),
          suggestedTweet = _EmberGetProperties.suggestedTweet,
          twitterUrl = _EmberGetProperties.twitterUrl;

      var url = "".concat(baseUrl, "?url=").concat(twitterUrl);

      if (suggestedTweet) {
        url += "&text=".concat(encodeURIComponent(suggestedTweet));
      }

      return url;
    })
  });

  _exports.default = _default;
});