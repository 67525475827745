define("moments/campaign/route", ["exports", "moments/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _window = window,
      bootstrapModels = _window.bootstrapModels;

  var _default = Ember.Route.extend({
    queryParamsService: Ember.inject.service(),
    actions: {
      error: function error(_error) {
        if (!_environment.default.APP.inDevelopment) {
          if (window.Bugsnag) window.Bugsnag.notify(_error);
          this.transitionTo('not-found');
        }
      }
    },
    beforeModel: function beforeModel() {
      if (bootstrapModels) {
        this.store.pushPayload(bootstrapModels);
      }
    },
    model: function model(params) {
      var draft = this.get('queryParamsService').findParam('draft');
      var preview = this.get('queryParamsService').findParam('preview') || draft;
      var campaignId = ((0, _jquery.default)('meta[name="campaign-id"]').attr('content') || params.campaign_id || '').toLowerCase();

      if (bootstrapModels) {
        return this.store.peekRecord('campaign', campaignId);
      } else {
        return this.store.queryRecord('campaign', {
          id: campaignId,
          preview: preview,
          draft: draft
        });
      }
    }
  });

  _exports.default = _default;
});