define("moments/components/content-piece/form-field/slider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['slider-content-wrapper'],

    /*------------ Variables ------------*/
    defaultValue: 0,
    didSubmitForm: false,
    formField: null,
    noUserVote: true,
    // this is for error messages

    /*------- Computed properties -------*/
    sliderProperties: Ember.computed.alias('formField.additionalProperties'),
    sliderValue: Ember.computed.alias('formField.value'),
    didSubmitWithoutVoting: Ember.computed.and('didSubmitForm', 'noUserVote'),
    errorMessage: Ember.computed('formField.validations.attrs.value.messages', function () {
      return this.get('formField.validations.attrs.value.messages') || [];
    }),
    effectiveValue: Ember.computed('defaultValue', 'sliderValue', function () {
      return this.get('sliderValue') || this.get('defaultValue');
    }),
    actions: {
      changeValue: function changeValue(event) {
        var value = event.target.value;
        var maxVal = this.get('sliderProperties.range.max');
        var minVal = this.get('sliderProperties.range.min');
        var normalizedValue = (value - minVal) / (maxVal - minVal) * 100;
        this.set('sliderValue', value);
        this.set('noUserVote', false); // position the thumb and add the fill to the slider

        this.setSliderFill(normalizedValue);
        this.setSliderThumb(normalizedValue);
      }
    },

    /*------------ Functions ------------*/
    // show tooltip on desktop
    mouseDown: function mouseDown(event) {
      this.toggleTooltip(event, 'block');
    },
    // hide tooltip on desktop
    mouseUp: function mouseUp(event) {
      this.toggleTooltip(event, 'none');
    },
    setSliderFill: function setSliderFill(value) {
      var sliderFillSelector = this.element.querySelector('.slider-fill');
      var backgroundColor = this.get('sliderProperties.colors.background');
      var fillColor = this.get('sliderProperties.colors.fill'); // create the gradient to fill the slider

      var gradient = "linear-gradient(90deg, ".concat(fillColor, " ").concat(value, "%, ").concat(backgroundColor, " ").concat(value + 0.1, "%)");
      sliderFillSelector.style.backgroundImage = gradient;
    },
    setSliderThumb: function setSliderThumb(value) {
      var thumbSelector = this.element.querySelector('.slider-thumb');
      var thumbSize = getComputedStyle(thumbSelector).getPropertyValue('width');
      var newLeft = "calc(".concat(value, "% - ").concat(parseInt(thumbSize) / 2, "px)");
      thumbSelector.style.left = newLeft;
    },
    toggleTooltip: function toggleTooltip(event, displayValue) {
      if (event.target === this.element.querySelector('.hidden-slider')) {
        var textSelector = this.element.querySelector('.slider-tooltip');
        textSelector.style.display = displayValue;
      }
    },
    // hide tooltip on mobile
    touchEnd: function touchEnd(event) {
      this.toggleTooltip(event, 'none');
    },
    // show tooltip on mobile
    touchStart: function touchStart(event) {
      this.toggleTooltip(event, 'block');
    }
  });

  _exports.default = _default;
});