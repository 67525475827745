define("moments/services/google-tag-manager", ["exports", "striptags"], function (_exports, _striptags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    trackingEnabled: window.ga && window.dataLayer,
    sendFormSubmission: function sendFormSubmission(contentCollection, formFields) {
      var trackingEnabled = this.get('trackingEnabled');

      if (!trackingEnabled || !contentCollection || !formFields) {
        return;
      }

      var formId = contentCollection.get('id');
      var data = {
        'event': 'formSubmit',
        'formId': formId
      };
      formFields.forEach(function (formField) {
        var key = (0, _striptags.default)(formField.get('text'));
        var value = formField.get('type') === 'checkbox' ? formField.get('value') : (0, _striptags.default)(formField.get('value'));
        data[key] = value;
      });
      window.dataLayer.push(data);
    },
    sendPageView: function sendPageView(contentCollection) {
      var trackingEnabled = this.get('trackingEnabled');

      if (!trackingEnabled || !contentCollection) {
        return;
      }

      var pageId = contentCollection.get('id');
      var pageName = contentCollection.get('contentPieces').sortBy('displayOrder').filterBy('type', 'text').mapBy('value').objectAt(0);
      window.dataLayer.push({
        'event': 'pageView',
        'pageId': pageId,
        'pageName': (0, _striptags.default)(pageName)
      });
    },
    sendResponse: function sendResponse(contentCollection, contentPiece) {
      var trackingEnabled = this.get('trackingEnabled');

      if (!trackingEnabled || !contentCollection || !contentPiece) {
        return;
      }

      var pageId = contentCollection.get('id');
      var actionId = contentPiece.get('id');
      var actionText = contentPiece.get('value');
      window.dataLayer.push({
        'event': 'userAction',
        'pageId': pageId,
        'actionId': actionId,
        'actionText': (0, _striptags.default)(actionText)
      });
    }
  });

  _exports.default = _default;
});