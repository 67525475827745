define("moments/components/content-piece/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQI/fo4E",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",false],[12,\"class\",[29,[\"basic-button \",[22,\"buttonClass\"],\" \",[22,\"styleClass\"]]]],[12,\"style\",[22,\"paddingCSS\"]],[3,\"action\",[[23,0,[]],\"click\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"live-polling-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"widthOrHeightClass\"],\" animation\"]]],[11,\"style\",[22,\"livePollingStyle\"]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"poll-number\"],[8],[0,\"\\n        \"],[1,[22,\"percentage\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"content \",[22,\"contentClass\"]]]],[8],[0,\"\\n    \"],[1,[24,[\"contentPiece\",\"value\"]],true],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/button/template.hbs"
    }
  });

  _exports.default = _default;
});