define("moments/components/content-piece/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PtZoXPyC",
    "block": "{\"symbols\":[\"formField\"],\"statements\":[[7,\"form\",true],[11,\"class\",[29,[\"content \",[22,\"contentPieceClass\"]]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedFormFields\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"content-piece/form-field\",null,[[\"didSubmitForm\",\"formField\",\"randomizeContent\",\"onClick\",\"onSocialLoginClick\"],[[24,[\"didSubmitForm\"]],[23,1,[]],[24,[\"randomizeContent\"]],[28,\"action\",[[23,0,[]],\"click\"],null],[28,\"action\",[[23,0,[]],\"socialLoginClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form/template.hbs"
    }
  });

  _exports.default = _default;
});