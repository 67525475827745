define("moments/utils/escape-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = escapeCss;

  function escapeCss(unsafeContent) {
    return Ember.Handlebars.Utils.escapeExpression(unsafeContent);
  }
});