define("moments/components/content-piece/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uu7INXGu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"contentPiece\",\"jsonValue\",\"link\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\",true],[11,\"href\",[24,[\"contentPiece\",\"jsonValue\",\"link\"]]],[10,\"target\",\"_blank\"],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"imageClass\"]]]],[11,\"style\",[22,\"imageStyle\"]],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"imageSrc\"]]]],[10,\"class\",\"hidden-image\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"imageClass\"]]]],[11,\"style\",[22,\"imageStyle\"]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"imageSrc\"]]]],[10,\"class\",\"hidden-image\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/image/template.hbs"
    }
  });

  _exports.default = _default;
});